<template>
  <div class="ElementsSociaux" >
    <validationCartouche
      :hasCedantValidated="elementsSociauxActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="elementsSociauxActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Éléments sociaux de l’activité cédée </h2>


      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="elementsSociauxActiviteCedee.has_repreneur_validated && elementsSociauxActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>


      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="elementsSociauxActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="elementsSociauxActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyElementsSociaux"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="elementsSociauxActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="elementsSociauxActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{elementsSociauxActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>,
        il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>


      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': $v.elementsSociauxActiviteCedee.nb_salaries.$dirty ? $v.elementsSociauxActiviteCedee.nb_salaries.$error : null  }">
        <label class="gavc-label" for="elementsSociauxActiviteCedee.nb_salaries-group">
          Nombre de salariés
          <span class="gavc-required"> * </span>
        </label>
        <input
          :disabled="!cedantCanModified"
          class="gavc-input"
          id="elementsSociauxActiviteCedee.nb_salaries-group"
          @input="$v.elementsSociauxActiviteCedee.nb_salaries.$touch()"
          type="number"
          @change="modifyElementsSociaux"
          placeholder="Nombre de salariés au sein de votre entreprise"
          v-model="elementsSociauxActiviteCedee.nb_salaries"
        >
      </div>

      <div class="mt-4" v-if="elementsSociauxActiviteCedee.nb_salaries == 0">
        <p class="gavc-chapo" v-if="userGroupContains(['CEDANT'])">
          Votre entreprise cédée ne contient aucun salarié, vous n'avez aucun document à charger et
          pouvez directement valider cette section pour passer à l'étape suivante (lorsque le repreneur aura
          également validé cette section)
        </p>
        <p class="gavc-chapo" v-else>
          Le cédant a déclaré que l'entreprise cédée ne contenait aucun salarié, il n'a donc aucun document à charger.
          Vous pouvez directement valider cette section pour passer à l'étape suivante (lorsque le cédant aura
          également validé cette section)
        </p>
      </div>

      <div v-if="elementsSociauxActiviteCedee.nb_salaries > 0">

        <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.masse-salariale-collapse>
          Masse salariale
          <span class="when-opened float-right">
            <v-icon name="chevron-down" />
          </span>
          <span class="when-closed float-right">
            <v-icon name="chevron-right" />
          </span>
        </b-button>

        <b-collapse id="masse-salariale-collapse" role="tabpanel">


          <h4 class="gavc-h4 mt-4"> Contrats de travail, apprentissage et professionnalisation </h4>
          <p>
            Documents à charger : <strong>Contrats de travail, apprentissage et professionnalisation</strong>
            <span
              v-b-tooltip.hover title="Pour consulter les documents relatifs à vos salariés, rapprochez vous du service RH"
            >
              <v-icon
                class="ml-2 gavc-text-tory"
                name="info-circle"
              />
            </span>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'CONTRAT_TRAVAIL', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('CONTRAT_TRAVAIL', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'CONTRAT_TRAVAIL', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="CONTRAT_TRAVAIL"
          />

          <h4 class="gavc-h4 mt-4">Bulletins de salaire des trois derniers mois</h4>
          <p>
            Documents à charger : <strong>Bulletins de salaire des trois derniers mois</strong>
            <span
              v-b-tooltip.hover title="Pour consulter les documents relatifs à vos salariés, rapprochez vous du service RH"
            >
              <v-icon
                class="ml-2 gavc-text-tory"
                name="info-circle"
              />
            </span>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'BULLETIN_SALAIRE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('BULLETIN_SALAIRE', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'BULLETIN_SALAIRE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="BULLETIN_SALAIRE"
          />

          <h4 class="gavc-h4 mt-4">Attestations de non préavis de licenciement ou démission</h4>
          <p>
            Documents à charger : <strong>Attestations de non préavis de licenciement ou démission</strong>
            <span
              v-b-tooltip.hover title="Pour consulter les documents relatifs à vos salariés, rapprochez vous du service RH"
            >
              <v-icon
                class="ml-2 gavc-text-tory"
                name="info-circle"
              />
            </span>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'ATTESTATION_NON_PREAVIS', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('ATTESTATION_NON_PREAVIS', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'ATTESTATION_NON_PREAVIS', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="ATTESTATION_NON_PREAVIS"
          />
        </b-collapse>


        <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.garanties-collectives-collapse>
          Garanties collectives
          <span class="when-opened float-right">
            <v-icon name="chevron-down" />
          </span>
          <span class="when-closed float-right">
            <v-icon name="chevron-right" />
          </span>
        </b-button>

        <b-collapse id="garanties-collectives-collapse" role="tabpanel">
          <p class="gavc-p">
            Documents à charger : <strong>Garanties collectives : Contrats de garantie santé, de prévoyance, etc.</strong>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'GARANTIES_COLLECTIVES', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('GARANTIES_COLLECTIVES', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'GARANTIES_COLLECTIVES', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="GARANTIES_COLLECTIVES"
          />
        </b-collapse>

        <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.documentation-sociale-collapse>
          Documentation sociale
          <span class="when-opened float-right">
            <v-icon name="chevron-down" />
          </span>
          <span class="when-closed float-right">
            <v-icon name="chevron-right" />
          </span>
        </b-button>

        <b-collapse id="documentation-sociale-collapse" role="tabpanel">
          <h4 class="gavc-h4 mt-4">Déclaration Unifiée des Cotisations Sociales</h4>
          <p>
            Documents à charger : <strong>Déclaration Unifiée des Cotisations Sociales (DUCS) des trois derniers mois</strong>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'DECLARATION_UNIFIEE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('DECLARATION_UNIFIEE', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_UNIFIEE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="DECLARATION_UNIFIEE"
          />

          <h4 class="gavc-h4 mt-4">Autre documentation sociale</h4>
          <p>
            Documents à charger :
            <strong>
              Toute autre documentation sociale :
              accord d‘intéressement ou de participation, contentieux social
              (jugements, conclusions, correspondances), etc.
            </strong>
          </p>

          <auditItem
            :disabled="!cedantCanModified"
            @document-deleted="deleteAuditItem($event, 'elementsSociauxActiviteCedee')"
            @document-download="downloadAuditDocument($event, 'elementsSociauxActiviteCedee')"
            @document-uploaded="createAuditDocument($event, 'AUTRE_DOCUMENTATION_SOCIALE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            @item-validated="validateAuditItem($event, 'elementsSociauxActiviteCedee', true)"
            @item-unvalidated="validateAuditItem($event, 'elementsSociauxActiviteCedee', false)"
            :items="filterDocuments('AUTRE_DOCUMENTATION_SOCIALE', 'CEDANT')"
            @justificatif-added="createAuditItemWithoutDocument($event, 'AUTRE_DOCUMENTATION_SOCIALE', elementsSociauxActiviteCedee.id, 'elementsSociauxActiviteCedee')"
            type="AUTRE_DOCUMENTATION_SOCIALE"
          />
        </b-collapse>

      </div>
      <div v-if="elementsSociauxActiviteCedee.nb_salaries === 0 || elementsSociauxActiviteCedee.nb_salaries > 0">
        <!-- ######## CEDANT BUTTONS ########## -->
        <div v-if="!elementsSociauxActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
          <div class="col-12 text-right">
            <validationSectionButton
              @modify="modifyElementsSociaux"
              @validate="validateElementsSociaux(true)"
            />
          </div>
        </div>
        <div v-else-if="elementsSociauxActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
          <div class="col-12 text-right">
            <button
              class="gavc-btn gavc-btn--bone"
              @click="validateElementsSociaux(false)"
            >
                Invalider cette étape
            </button>
          </div>
        </div>

        <div v-if="!elementsSociauxActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
          <div class="col-12 text-right">
            <validationSectionButton
              @validate="validateElementsSociaux(true)"
              :withModifyButton="false"
            />
          </div>
        </div>
        <div v-else-if="elementsSociauxActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
          <div class="col-12 text-right">
            <button
              class="gavc-btn gavc-btn--bone"
              @click="validateElementsSociaux(false)"
            >
                Invalider cette étape
            </button>
          </div>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="elementsSociauxActiviteCedee.has_cedant_validated"
            text="Le cédant a validé toute la section"
          />
        </div>
        <div class="col-12 col-md-6 pl-4 pr-4">
          <hasValidatedMessage
            v-if="elementsSociauxActiviteCedee.has_repreneur_validated"
            text="Le repreneur a validé toute la section"
          />
        </div>
      </div>

      <div class="mt-4 text-center" v-if="elementsSociauxActiviteCedee.has_repreneur_validated && elementsSociauxActiviteCedee.has_cedant_validated">
        <button
          class="gavc-btn"
          @click="$emit('next-step')">
              Étape validée - Accéder à l'étape suivante
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { integer, minValue, maxValue,  required } from 'vuelidate/lib/validators'

import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')


export default {
  name: 'ElementsSociaux',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    userMixins,
    uxMixins,
    validationMixin
  ],
  data: function () {
    return {
      isGettingElementsSociaux: false,
      elementsSociauxActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],

        nb_salaries: null,
      },
    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.elementsSociauxActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getElementsSociaux()
  },

  validations: {
    elementsSociauxActiviteCedee: {
      nb_salaries: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(100000),
        integer
      }
    },
  },
  methods: {
    filterDocuments (type, uploader) {
      return this.elementsSociauxActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    documentModified () {
      this.getElementsSociaux()
    },
    getElementsSociaux () {
      this.isGettingElementsSociaux = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'elementsSociauxActiviteCedee', this.id)
      .then((result) => {
        this.elementsSociauxActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsSociauxActiviteCedee getElementsSociaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingElementsSociaux = false
      })
    },

    modifyElementsSociaux () {
      if (this.elementsSociauxActiviteCedee.nb_salaries === "") {
        return
      }
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'elementsSociauxActiviteCedee', this.id, this.elementsSociauxActiviteCedee)
      .then((result) => {
        this.elementsSociauxActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsSociauxActiviteCedee modifyElementsSociaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateElementsSociaux (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "elementsSociauxActiviteCedee",  this.id, data)
      .then((result) => {
        this.elementsSociauxActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        if (validationStatus) {
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
            else {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('elementsSociauxActiviteCedee validateElementsSociaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
